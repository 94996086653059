import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd, HeadingSm } from '../components/heading/Heading'
import { SiteHeader } from '../components/site-header/SiteHeader'
import { SiteFooter } from '../components/site-footer/SiteFooter'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Cards } from '../components/cards/Cards'

const PraktijkPage = () => {
  return (
    <Layout title="De Praktijk" desc="De praktijk ligt in een rustige woonwijk, met voldoende parkeergelegenheid, aan de rand van het Gooimeer en makkelijk bereikbaar via het openbaar vervoer.">
      <SiteHeader />

      <TextBanner>
        <HeadingMd h={2}>Locatie & Bereikbaarheid</HeadingMd>
        <p>
          De praktijk ligt in een rustige woonwijk, met voldoende parkeergelegenheid, aan de rand van het Gooimeer en
          makkelijk bereikbaar via het openbaar vervoer.
        </p>

        <HeadingSm h={3}>Afspraak maken</HeadingSm>
        <p>
          De praktijk is telefonisch en via de  mail bereikbaar voor het maken van afspraken. Indien er niet wordt
          opgenomen kunt u inspreken dan wel een mail sturen vergezelt met het nummer waarop u bereikbaar bent.
        </p>

        <HeadingSm h={3}>Verhinderd?</HeadingSm>
        <p>Bent u verhinderd dan graag 24-uur van te voren afmelden.</p>
      </TextBanner>

      <Cards />

      <SiteFooter />
    </Layout>
  )
}

export default PraktijkPage
